import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/newlanding/mobile-1.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Drawer, Card } from "@material-tailwind/react";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("user_token");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsDrawerOpen(false)
    );
  }, []);

  return (
    <>
      <header className="header border-b border-[#2b426e]">
        <nav className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 h-full">
          <div className="2xl:block xl:block lg:block hidden">
            <div className="flex items-center justify-between md:px-0 px-1.5">
              <div className="flex-shrink-0 lg:flex-1">
                <img
                  className="2xl:w-[140px] xl:w-[130px] lg:w-[130px] h-full w-auto"
                  src={logo}
                  alt="logo"
                />
              </div>
              <div className="flex items-center">
                <div className="flex items-baseline space-x-3">
                  <a
                    href="/"
                    className={`font-semibold px-1 py-2 rounded-md ${
                      location.pathname === "/"
                        ? "text-[#ff6a00]"
                        : "text-[#0c0103]"
                    }`}
                  >
                    Home
                  </a>
                  <a
                    href="/services"
                    className={`font-semibold px-1 py-2 rounded-md ${
                      location.pathname === "/services"
                        ? "text-[#ff6a00]"
                        : "text-[#0c0103]"
                    }`}
                  >
                    All Services
                  </a>
                  <a
                    // href="/howitwork"
                    href="/howitworks"
                    className={`font-semibold px-1 py-2 rounded-md ${
                      //   location.pathname === "/howitwork"
                      location.pathname === "/howitworks"
                        ? "text-[#ff6a00]"
                        : "text-[#0c0103]"
                    }`}
                  >
                    How it work
                  </a>
                  <a
                    href="/costguide"
                    className={`font-semibold px-1 py-2 rounded-md ${
                      location.pathname === "/costguide"
                        ? "text-[#ff6a00]"
                        : "text-[#0c0103]"
                    }`}
                  >
                    Cost Guide
                  </a>
                  <a
                    href="https://gofixitnow.online/fixer/"
                    className="text-[#0c0103] font-semibold px-1 py-2 rounded-md"
                  >
                    Become a Fixer
                  </a>
                  <a
                    href="/signup"
                    className="bg-[#2b426e] text-white rounded-lg px-5 py-2.5 text-sm font-base"
                  >
                    Sign up
                  </a>
                  <a
                    href="/signin"
                    className="bg-[#2b426e] text-white rounded-lg px-5 py-2.5 text-sm font-base"
                  >
                    Log in
                  </a>
                  <a
                    href="http://158.220.84.148/fixer/signin"
                    className="bg-[#ff6a00] text-white rounded-lg px-5 py-2.5 text-sm font-base"
                  >
                    Fixer Login
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center h-full lg:mx-0 mx-2 2xl:hidden xl:hidden lg:hidden block">
            <div className="flex items-center">
              <IconButton
                variant="text"
                size="lg"
                onClick={openDrawer}
                className="p-0 w-8 h-8"
              >
                {isDrawerOpen ? (
                  <XMarkIcon className="h-5 w-5" strokeWidth={2} />
                ) : (
                  <Bars3Icon className="h-5 w-5" strokeWidth={2} />
                )}
              </IconButton>
            </div>
            <div className="">
              <img className="w-[100px]" src={logo} alt="Workflow" />
            </div>
          </div>
        </nav>
      </header>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="drawer">
        <Card color="transparent" shadow={false} className="p-4">
          <div className="mb-2 py-2 px-3 flex justify-between items-center">
            <img
              className="2xl:h-[56px] 2xl:w-[122px] xl:h-[56px] xl:w-[122px] h-12 w-auto"
              src={logo}
              alt="Workflow"
            />
            <div>
              <IconButton
                variant="text"
                color="blue-gray"
                onClick={(e) => setIsDrawerOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
          <div className="px-3 py-1 flex flex-col justify-between h-[85vh]">
            <div className="">
              <div
                className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#ff6a00] ${
                  location.pathname === "/"
                    ? "text-[#ff6a00]"
                    : "text-[#0c0103]"
                }`}
                onClick={(e) => navigate("/")}
              >
                Home
              </div>
              <div
                className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#ff6a00] ${
                  location.pathname === "/services"
                    ? "text-[#ff6a00]"
                    : "text-[#0c0103]"
                }`}
                onClick={(e) => navigate("/services")}
              >
                All Services
              </div>
              <div
                className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#ff6a00] ${
                  //   location.pathname === "/howitwork"
                  location.pathname === "/howitworks"
                    ? "text-[#ff6a00]"
                    : "text-[#0c0103]"
                }`}
                onClick={(e) => navigate("/howitworks")}
                // onClick={(e) => navigate("/howitwork")}
              >
                How it work
              </div>
              <div
                className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#ff6a00] ${
                  location.pathname === "/costguide"
                    ? "text-[#ff6a00]"
                    : "text-[#0c0103]"
                }`}
                onClick={(e) => navigate("/costguide")}
              >
                Cost Guide
              </div>
              <div className="my-2 rounded-md text-[#14151E] font-semibold px-1 py-2 text-base hover:text-[#ff6a00]">
                <a href="https://gofixitnow.online/fixer/">Become a Fixer</a>
              </div>
              <div
                className="my-3 rounded-lg gradient-button-blue my-1 text-white text-sm p-3 cursor-pointer text-center leading-tight transition-all outline-none pr-4"
                onClick={(e) => navigate("/signup")}
              >
                Sign up
              </div>
              <div
                className="my-2 rounded-lg gradient-button-blue text-white text-sm p-3 cursor-pointer text-center leading-tight transition-all outline-none pr-4"
                onClick={(e) => navigate("/signin")}
              >
                Log in
              </div>
            </div>
            <div className="rounded-lg bg-gradient-to-r from-[#FF3332] to-[#FE8E4C] my-1 text-white text-sm p-3 cursor-pointer text-center leading-tight transition-all outline-none pr-4">
              <a href="http://158.220.84.148/fixer/signin">Fixer Login</a>
            </div>
          </div>
        </Card>
      </Drawer>
    </>
  );
};

export default Header;
