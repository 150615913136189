import React, { useEffect, useState, Fragment } from "react";
import banner from "../../assets/images/pages/banner.png";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Collapse,
  IconButton,
  List,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import mail from "../../assets/images/pages/profle/mail.png";
import phone from "../../assets/images/pages/profle/phone.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Transition, Dialog } from "@headlessui/react";
import PhoneInput from "react-phone-input-2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Lottie from "react-lottie";
import * as animationData from "../../assets/images/json/delete.json";
import user1 from "../../assets/images/user/user4.png";
import user2 from "../../assets/images/user/user5.png";
import user3 from "../../assets/images/user/user6.png";
import locationicon from "../../assets/images/pages/location.png";
import { IoHeart } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  addFavouriteBookingApi,
  changePasswordApi,
  contactUsApi,
  deleteAccountApi,
  editProfileApi,
  getUserProfileApi,
  listFavouriteBookingApi,
} from "../../services/ApiServices";
import { useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { IMAGE_URL } from "../../services/Api";
import noImg from "../../assets/images/no-image.png";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const favorite_arr = [
  {
    id: 1,
    profile_pic: user1,
    name: "Michael Anderson",
    rating: "4.5",
    address: "New York, USA",
    category: "Flatpack Assembly",
    bookingId: 22,
  },
  {
    id: 2,
    profile_pic: user2,
    name: "Mac Jonson",
    rating: "4.3",
    address: "New York, USA",
    category: "Flatpack Assembly",
    bookingId: 22,
  },
  {
    id: 3,
    profile_pic: user3,
    name: "Michael Anderson",
    rating: "4.4",
    address: "New York, USA",
    category: "Flatpack Assembly",
    bookingId: 23,
  },
];

const Profile = () => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const [type, setType] = useState(1);
  let [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPass, setOldPass] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [selectedISOCode, setSelectedISOCode] = useState("");
  const [favouriteList, setFavouriteList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [stopPagination, setStopPagination] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const closeModal = (e) => {
    setIsOpen(false);
  };

  const openDeleteModal = (e) => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (e) => {
    setShowDeleteModal(false);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowOldPass = () => {
    setOldPass((show) => !show);
  };

  const handleConfirmPassword = () => {
    setConfirmPasswordShow((show) => !show);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const getProfileInfo = () => {
    getUserProfileApi()
      .then((res) => {
        const data = res.data.info;
        setFormValues(data);
        setSelectedISOCode(data.isoCode);
        setCountryCode(data.countryCode);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const listFavouriteInfo = () => {
    const params = {
      pageNo: pageNo,
    };
    listFavouriteBookingApi(params)
      .then((res) => {
        const data = res.data.info;
        if (!data.length) {
          setStopPagination(true);
        } else {
          setFavouriteList(data);
          setPageNo(pageNo);
        }
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    if (type === 3) {
      listFavouriteInfo();
    }
  }, [type, pageNo]);

  const handleNext = () => {
    if (!stopPagination) {
      setPageNo(pageNo + 1);
    }
  };

  const handleBack = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleDeleteAccount = () => {
    deleteAccountApi()
      .then((res) => {
        const data = res.data;
        const message = res.data.message;
        navigate("/signin");
        localStorage.removeItem("user_token");
        toast.success(message || "Your account deleted Successfully!!!");
      })
      .catch((err) => {
        const message = err.response.data.message;
        console.log("err >>>", err);
        toast.error(message || "Somthing went wrong!!");
      });
  };

  //edit profile
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .max(36, "First Name Must be 36 characters or less")
      .required("Please enter Your First Name"),
    lastName: yup
      .string()
      .max(36, "Last Name Must be 36 characters or less")
      .required("Please enter Your Last Name"),
    streetAddress: yup.string().required("Street Address is required"),
    phoneNumber: yup.string().required("Phone number is required!!!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: formValues.firstName || "",
      lastName: formValues.lastName || "",
      streetAddress: formValues.streetAddress || "",
      phoneNumber: `${formValues.countryCode}${formValues.phoneNumber}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const rawPhone = values.phoneNumber.replace(countryCode, "");
      const hasPlus = countryCode.startsWith("+");

      var formdata = new FormData();
      formdata.append("firstName", values.firstName);
      formdata.append("lastName", values.lastName);
      formdata.append("streetAddress", values.streetAddress);
      formdata.append("isoCode", selectedISOCode);
      formdata.append("countryCode", hasPlus ? countryCode : `+${countryCode}`);
      formdata.append("phoneNumber", `${rawPhone}`);

      editProfileApi(formdata)
        .then((res) => {
          const data = res.data.info;
          const message = res.data.message;

          toast.success(message || "Edit your profile Successfully!!!");
          getProfileInfo();
        })
        .catch((err) => {
          const message = err.response.data.message;
          console.log("err >>>", err);
          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  //change password
  const passwordValidationSchema = yup.object().shape({
    oldpass: yup.string().required("Password is required"),
    newpass: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=-{};:"<>,./?]).*$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
    confirm_password: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newpass"), null], "Passwords must match"),
  });
  const changePassformik = useFormik({
    initialValues: {
      oldpass: "",
      newpass: "",
      confirm_password: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      changePasswordApi(values)
        .then((res) => {
          const message = res.data.message;
          const data = res.data;
          toast.success(message || "Change Password Successfully!!!");
          navigate("/signin");
          localStorage.removeItem("user_token");
          localStorage.removeItem("userid");
          resetForm();
        })
        .catch((err) => {
          const message = err.response.data.message;

          console.log("err >>>", err);
          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  //contact us
  const contactUsSchema = yup.object().shape({
    emailId: yup
      .string()
      .required("Please enter Your Email Id")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),
    message: yup.string().required("Message is required"),
  });
  const contactUsformik = useFormik({
    initialValues: {
      emailId: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: (values, { resetForm }) => {
      contactUsApi(values)
        .then((res) => {
          const message = res.data.message;
          const data = res.data;
          toast.success(message || "Message Sended Successfully!!!");
          resetForm();
        })
        .catch((err) => {
          const message = err.response.data.message;

          console.log("err >>>", err);
          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  //favourite
  const handleClickLike = (e, id) => {
    const params = {
      bookingId: id,
    };
    addFavouriteBookingApi(params)
      .then((res) => {
        console.log("res >>>", res.data);
        const message = res.data.message;

        toast.success(
          message || "Booking details added to favourite collection!!!"
        );
        listFavouriteInfo();
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  return (
    <>
      <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 m-5">
        <div className="">
          <img
            src={banner}
            alt=""
            className="object-fit w-full xl:h-[175px] lg:h-[170px] md:h-[165px] h-[160px]"
          ></img>
        </div>
        <div className="2xl:flex xl:flex lg:flex md:flex 2xl:justify-between xl:justify-between lg:justify-between md:justify-between justify-center items-center 2xl:ml-14 xl:ml-14 lg:ml-14 md:ml-14 ml-4 mr-5">
          <div className="-mt-[50px]">
            <Avatar
              src={
                formValues.profilePic
                  ? IMAGE_URL + formValues.profilePic
                  : noImg
              }
              alt="avatar"
              size="xxl"
              withBorder={true}
              className="border-white"
              crossOrigin="anonymous"
            />
            <div className="my-4">
              <p className="ml-2 font-medium my-1">
                {`${formValues.firstName || "-"} ${formValues.lastName || ""}`}
              </p>
            </div>
          </div>
          <div className="2xl:py-4 xl:py-3 lg:py-3">
            <div className="">
              <div className="flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end items-center">
                <img alt="" src={phone} className="w-5 h-5"></img>
                <p className="ml-2 font-medium my-1">
                  {`${formValues.countryCode} ${formValues.phoneNumber}`}
                </p>
              </div>
              <div className="flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-end items-center">
                <img alt="" src={mail} className="w-5 h-5"></img>
                <p className="ml-2 font-medium my-1">{formValues.emailId}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex justify-between items-center py-3">
            <Navbar className="max-w-screen-xl p-0 text-black shadow-none max-w-full">
              <div className="flex items-center justify-between">
                <div className="flex">
                  <div className="gap-2 2xl:flex xl:flex lg:flex">
                    <div className="2xl:block xl:block hidden lg:flex 2xl:flex xl:flex">
                      <List className="mt-4 mb-6 p-0 2xl:mt-0 2xl:mb-0 2xl:flex-row 2xl:p-1 xl:mt-0 xl:mb-0 xl:flex-row xl:p-1 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
                        <div
                          className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                            type === 1 ? "border-b-2 border-[#0BAFCC]" : ""
                          }`}
                          onClick={(e) => setType(1)}
                        >
                          Edit Profile
                        </div>
                        <div
                          className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                            type === 2 ? "border-b-2 border-[#0BAFCC]" : ""
                          }`}
                          onClick={(e) => setType(2)}
                        >
                          Change Password
                        </div>
                        <div
                          className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                            type === 3 ? "border-b-2 border-[#0BAFCC]" : ""
                          }`}
                          onClick={(e) => setType(3)}
                        >
                          Favorite
                        </div>
                        <div
                          className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                            type === 4 ? "border-b-2 border-[#0BAFCC]" : ""
                          }`}
                          onClick={(e) => setType(4)}
                        >
                          Contact Us
                        </div>
                      </List>
                    </div>
                  </div>
                  <IconButton
                    variant="text"
                    color="blue-gray"
                    className="2xl:hidden xl:hidden lg:hidden block p-2 my-2"
                    onClick={() => setOpenNav(!openNav)}
                  >
                    {openNav ? (
                      <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                      <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                  </IconButton>
                </div>
                <div className="">
                  <p
                    className="text-danger cursor-pointer text-sm"
                    onClick={(e) => openDeleteModal(e)}
                  >
                    Delete Account
                  </p>
                </div>
              </div>
              <Collapse open={openNav}>
                <List className="mt-4 mb-6 p-0 2xl:mt-0 2xl:mb-0 2xl:flex-row 2xl:p-1 xl:mt-0 xl:mb-0 xl:flex-row xl:p-1 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
                  <div
                    className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                      type === 1 ? "border-b-2 border-[#0BAFCC]" : ""
                    }`}
                    onClick={(e) => {
                      setType(1);
                      setOpenNav(false);
                    }}
                  >
                    Edit Profile
                  </div>
                  <div
                    className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                      type === 2 ? "border-b-2 border-[#0BAFCC]" : ""
                    }`}
                    onClick={(e) => {
                      setType(2);
                      setOpenNav(false);
                    }}
                  >
                    Change Password
                  </div>
                  <div
                    className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                      type === 3 ? "border-b-2 border-[#0BAFCC]" : ""
                    }`}
                    onClick={(e) => {
                      setType(3);
                      setOpenNav(false);
                    }}
                  >
                    Favorite
                  </div>
                  <div
                    className={`mx-2 cursor-pointer text-black font-medium text-sm p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                      type === 4 ? "border-b-2 border-[#0BAFCC]" : ""
                    }`}
                    onClick={(e) => {
                      setType(4);
                      setOpenNav(false);
                    }}
                  >
                    Contact Us
                  </div>
                </List>
              </Collapse>
            </Navbar>
          </div>
          {type === 1 ? (
            <div className="grid grid-cols-12">
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 p-3 table-row-shadow rounded-[10px]">
                <form
                  onSubmit={(e) => {
                    formik.handleSubmit();
                    e.preventDefault();
                    return false;
                  }}
                >
                  <div className="2xl:p-4 xl:p-4 p-4">
                    <div className="">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-4"
                      >
                        First Name
                      </label>
                      <input
                        id="regular-form-4"
                        name="firstName"
                        type="text"
                        placeholder="Enter here"
                        className="focus:outline-none py-3 px-4 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      ></input>
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-5"
                      >
                        Last Name
                      </label>
                      <input
                        id="regular-form-5"
                        name="lastName"
                        type="text"
                        placeholder="Enter here"
                        className="focus:outline-none py-3 px-4 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      ></input>
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-9"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        name="phoneNumber"
                        country={countryCode}
                        enableAreaCodes={true}
                        copyNumbersOnly
                        value={formik.values.phoneNumber}
                        onChange={(phone, event) => {
                          formik.setFieldValue("phoneNumber", phone);
                          //+
                          setCountryCode(event.dialCode);
                          //string
                          setSelectedISOCode(event.countryCode);
                        }}
                      />
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
                        htmlFor="regular-form-3"
                      >
                        Street Address
                      </label>
                      <input
                        id="regular-form-3"
                        name="streetAddress"
                        type="text"
                        placeholder="Type here"
                        className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.streetAddress}
                      />
                      {formik.touched.streetAddress &&
                      formik.errors.streetAddress ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.streetAddress}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <Button
                        type="submit"
                        className="text-sm font-normal capitalize bg-primary 2xl:w-[50%] xl:w-[50%] lg:w-full w-full"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : type === 2 ? (
            <div className="grid grid-cols-12">
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 p-3 table-row-shadow rounded-[10px]">
                <form onSubmit={changePassformik.handleSubmit}>
                  <div className="2xl:p-4 xl:p-4 p-4">
                    <div className="">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        Old Password
                      </label>
                      <div className="relative">
                        <input
                          id="regular-form-2"
                          name="oldpass"
                          type={oldPass ? "text" : "password"}
                          placeholder="Enter Password"
                          className="focus:outline-none py-3 ps-4 pe-11 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={changePassformik.handleChange}
                          onBlur={changePassformik.handleBlur}
                          value={changePassformik.values.oldpass}
                        />
                        <div className="" onClick={handleShowOldPass}>
                          {oldPass ? (
                            <FaEye className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          ) : (
                            <FaEyeSlash className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          )}
                        </div>
                      </div>
                      {changePassformik.touched.oldpass &&
                      changePassformik.errors.oldpass ? (
                        <div className="text-danger my-1 text-sm">
                          {changePassformik.errors.oldpass}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          id="regular-form-2"
                          name="newpass"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          className="focus:outline-none py-3 ps-4 pe-11 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={changePassformik.handleChange}
                          onBlur={changePassformik.handleBlur}
                          value={changePassformik.values.newpass}
                        />
                        <div className="" onClick={handleShowPassword}>
                          {showPassword ? (
                            <FaEye className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          ) : (
                            <FaEyeSlash className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          )}
                        </div>
                      </div>
                      {changePassformik.touched.newpass &&
                      changePassformik.errors.newpass ? (
                        <div className="text-danger my-1 text-sm">
                          {changePassformik.errors.newpass}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        Confirm New Password
                      </label>
                      <div className="relative">
                        <input
                          id="regular-form-2"
                          name="confirm_password"
                          type={confirmPasswordShow ? "text" : "password"}
                          placeholder="Enter Password"
                          className="focus:outline-none py-3 ps-4 pe-11 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={changePassformik.handleChange}
                          onBlur={changePassformik.handleBlur}
                          value={changePassformik.values.confirm_password}
                        />
                        <div className="" onClick={handleConfirmPassword}>
                          {confirmPasswordShow ? (
                            <FaEye className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          ) : (
                            <FaEyeSlash className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                          )}
                        </div>
                      </div>
                      {changePassformik.touched.confirm_password &&
                      changePassformik.errors.confirm_password ? (
                        <div className="text-danger my-1 text-sm">
                          {changePassformik.errors.confirm_password}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <Button
                        className="text-sm font-normal capitalize bg-primary 2xl:w-[50%] xl:w-[50%] lg:w-full w-full"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : type === 3 ? (
            <div className="p-3 pb-8">
              <div className="grid grid-cols-12 gap-6 mt-3">
                {favouriteList.map((item, index) => {
                  return (
                    <div
                      className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 h-full"
                      key={index}
                    >
                      <Card
                        color="transparent"
                        className="p-4 table-row-shadow rounded-lg h-auto"
                      >
                        <CardHeader
                          color="transparent"
                          className="mx-0 flex items-center justify-between gap-4 mt-0 shadow-none"
                        >
                          <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap flex-wrap items-center gap-3">
                            <div className="h-full w-full">
                              <img
                                src={
                                  IMAGE_URL +
                                  item?.Booking?.bookingto?.profilePic
                                }
                                alt="profile"
                                crossOrigin="anonymous"
                                className="w-[74px] h-[74px] rounded-xl object-cover object-center"
                              />
                            </div>
                            <div className="flex w-full flex-col gap-0.5">
                              <div className="flex items-center justify-between">
                                <Typography
                                  variant="h5"
                                  color="blue-gray"
                                  className="font-medium whitespace-nowrap"
                                >
                                  {`${item?.Booking?.bookingto?.firstName} ${item?.Booking?.bookingto?.lastName}`}
                                </Typography>
                              </div>
                              <div className="flex flex-wrap 2xl:gap-2 xl:gap-0 gap-2 items-center mb-2">
                                <div className="flex items-center gap-0">
                                  {[1, 2, 3, 4, 5].map((star) => (
                                    <span
                                      key={star}
                                      className="cursor-pointer"
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          item?.Booking?.bookingto?.avgRating >=
                                          star
                                            ? "#FFC107"
                                            : "#D7D7D7",
                                        fontSize: "25px",
                                        padding: "0px",
                                      }}
                                    >
                                      {" "}
                                      ★{" "}
                                    </span>
                                  ))}
                                </div>
                                <div className="flex whitespace-nowrap items-center">
                                  <p className="text-sm">
                                    {Number(
                                      item?.Booking?.bookingto?.avgRating
                                    ).toFixed(1)}{" "}
                                  </p>
                                  <p className="text-sm ml-1">
                                    ({item?.Booking?.bookingto?.totalRating}{" "}
                                    Reviews)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <IconButton
                              className="bg-[#0000001a] rounded-full"
                              onClick={(e) =>
                                handleClickLike(e, item.bookingId)
                              }
                            >
                              <IoHeart className="w-5 h-5" color="#FF6060" />
                            </IconButton>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0 my-3">
                          <div className="flex">
                            <img
                              src={locationicon}
                              alt=""
                              className="w-4.5 h-5 p-0.5"
                            ></img>
                            <p className="font-normal text-sm my-0.5 ml-1">
                              {item?.Booking?.bookingto?.streetAddress}
                            </p>
                          </div>
                          <div className="py-2">
                            <h6 className="text-primary font-medium text-sm break-normal">
                              {item?.Booking?.mainServicedata?.serviceName ||
                                ""}
                            </h6>
                          </div>
                        </CardBody>
                        <CardFooter className="flex justify-end p-0">
                          <Button
                            className="text-sm font-normal bg-primary capitalize"
                            onClick={(e) =>
                              navigate(`/bookappointment/${item.bookingId}`)
                            }
                          >
                            Book Appointment
                          </Button>
                        </CardFooter>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <div className="flex gap-3 justify-end items-center py-3">
                <IconButton
                  variant="outlined"
                  size="sm"
                  className="rounded-full border-[#B4B4B4]"
                  onClick={(e) => handleBack(e)}
                >
                  <MdOutlineChevronLeft className="w-6 h-6" color="#071D33" />
                </IconButton>
                <IconButton
                  variant="outlined"
                  size="sm"
                  className="rounded-full border-[#B4B4B4]"
                  onClick={(e) => handleNext(e)}
                >
                  <MdOutlineChevronRight className="w-6 h-6" color="#071D33" />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-12">
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 p-3 table-row-shadow rounded-[10px]">
                <form onSubmit={contactUsformik.handleSubmit}>
                  <div className="2xl:p-4 xl:p-4 p-4">
                    <div className="">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        Email
                      </label>
                      <input
                        id="regular-form-2"
                        name="emailId"
                        type="text"
                        placeholder="Enter Email"
                        className="focus:outline-none py-3 ps-4 pe-11 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                        onChange={contactUsformik.handleChange}
                        onBlur={contactUsformik.handleBlur}
                        value={contactUsformik.values.emailId}
                      />
                      {contactUsformik.touched.emailId &&
                      contactUsformik.errors.emailId ? (
                        <div className="text-danger my-1 text-sm">
                          {contactUsformik.errors.emailId}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-2"
                      >
                        Message
                      </label>
                      <div className="relative">
                        <textarea
                          name="message"
                          rows="4"
                          cols=""
                          placeholder="Type here"
                          className="focus:outline-none py-3 ps-4 pe-11 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                          onChange={contactUsformik.handleChange}
                          onBlur={contactUsformik.handleBlur}
                          value={contactUsformik.values.message}
                        ></textarea>
                      </div>
                      {contactUsformik.touched.message &&
                      contactUsformik.errors.message ? (
                        <div className="text-danger my-1 text-sm">
                          {contactUsformik.errors.message}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-5">
                      <Button
                        className="capitalize bg-primary 2xl:w-[20%] font-medium text-normal text-white"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => closeModal(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="rounded-none w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-center items-center py-3">
                    <div>
                      <h3 className="text-center text-lg font-medium leading-6 text-gray-900">
                        Withdraw Amount
                      </h3>
                      <Typography className="text-center py-3 text-sm font-medium">
                        Available Balance
                      </Typography>
                      <Typography className="text-center py-3 font-semibold text-3xl text-black">
                        £5000.00
                      </Typography>
                      <div className="flex">
                        <div className="flex">
                          <Checkbox
                            id="form-input-1"
                            className="checked:bg-primary checked:border-primary text-sm font-medium"
                          />
                          <label
                            htmlFor="form-input-1"
                            className="text-sm p-3 pl-0"
                          >
                            All Amount
                          </label>
                        </div>
                        <div className="ml-3 flex">
                          <Checkbox
                            id="form-input-2"
                            className="checked:bg-primary checked:border-primary text-sm font-medium"
                          />
                          <label
                            htmlFor="form-input-2"
                            className="text-sm p-3 pl-0"
                          >
                            Other Amount
                          </label>
                        </div>
                      </div>
                      <div className="mt-3">
                        <label
                          className="block text-xs font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-3"
                        >
                          Amount
                        </label>
                        <input
                          id="regular-form-3"
                          name="amount"
                          type="number"
                          placeholder="Enter amount"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[255px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center xl:py-5 py-3">
                    <Button
                      type="button"
                      className="capitalize inline-flex justify-center rounded-xl px-8 py-3 text-sm font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => closeModal(e)}
                      variant="text"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="ml-3 capitalize inline-flex justify-center rounded-xl border border-transparent bg-primary px-8 py-3 text-sm font-normal text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => closeModal(e)}
                    >
                      Yes, Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => closeDeleteModal(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="rounded-none w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className="py-2">
                    <div className="my-2 flex justify-center items-center">
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      {/* <img src={successimg} className="w-36 h36" alt=""></img> */}
                    </div>
                    <div className="my-2">
                      <h3 className="text-center text-2xl font-semibold leading-6 text-gray-900">
                        Delete Account
                      </h3>
                      <Typography className="text-center py-2 text-sm font-normal text-[#7E7E7E]">
                        Are you certain you wish to proceed with <br /> deleting
                        the account?
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center py-3">
                    <Button
                      type="button"
                      className="capitalize inline-flex justify-center rounded-xl px-8 py-3 text-sm font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => closeDeleteModal(e)}
                      variant="text"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="ml-3 capitalize inline-flex justify-center rounded-xl border border-transparent bg-danger px-8 py-3 text-sm font-normal text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => handleDeleteAccount()}
                    >
                      Yes, Delete
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Profile;
