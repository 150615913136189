import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Drawer,
  Card,
  Spinner,
} from "@material-tailwind/react";
import logoutimg from "../../assets/images/pages/logout.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logosm from "../../assets/images/logo/logo.png";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { GoChevronRight } from "react-icons/go";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import locationimg from "../../assets/images/Landing/location.png";
import {
  getNotificationListApi,
  getUserProfileApi,
  logoutApi,
} from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import noImg from "../../assets/images/no-image.png";
import { messaging, onMessage } from "../../firebase";
import Popover from "../Popover/Popover";
import { ThreeDots } from "react-loader-spinner";
import { Bell, Loader } from "lucide-react";
import ReactTimeAgo from "react-time-ago";
import InfiniteScroll from "react-infinite-scroll-component";

const ProfileMenu = () => {
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);

  const openDeleteModal = (e) => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (e) => {
    setShowDeleteModal(false);
  };

  const handleLogOut = () => {
    setLoader(true);
    if (token) {
      setLoader(true);
      logoutApi()
        .then((res) => {
          navigate("/signin");
          localStorage.removeItem("user_token");
          setLoader(false);
        })
        .catch((err) => {
          const status = err.response.status;
          if (status === 401) {
            navigate("/signin");
            localStorage.removeItem("user_token");
            setLoader(false);
          }
          console.log("err >>>", err);
        });
    } else {
      setLoader(false);
      navigate("/signin");
      localStorage.removeItem("user_token");
    }
  };

  const getProfileInfo = () => {
    getUserProfileApi()
      .then((res) => {
        const data = res.data.info;
        setProfileData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="mx-1 flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto hover:bg-none active:bg-white hover:bg-white outline-none"
          >
            <Avatar
              variant="circular"
              size="sm"
              alt="tania andrew"
              className="border border-gray-900 p-0.5"
              crossOrigin="anonymous"
              src={
                profileData.profilePic
                  ? IMAGE_URL + profileData.profilePic
                  : noImg
              }
            />
          </Button>
        </MenuHandler>
        <MenuList className="p-1">
          <MenuItem
            onClick={(e) => {
              navigate("/profile");
            }}
            className={`py-3 px-4 flex items-center gap-2 rounded justify-between items-center text-primary font-medium hover:text-primary`}
          >
            <Typography as="span" variant="small" className="font-normal">
              My Profile
            </Typography>
            <GoChevronRight className="" />
          </MenuItem>
          <MenuItem
            onClick={(e) => openDeleteModal()}
            className={`py-3 px-4 flex items-center gap-2 rounded items-center text-red-700`}
          >
            <img src={logoutimg} alt="" className="w-5 h-5" />
            <Typography
              as="span"
              variant="small"
              className="font-normal text text-red-700"
            >
              Log Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>

      <Transition appear show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => closeDeleteModal(e)}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="rounded-none w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className="py-3">
                    <div className="py-2">
                      <h3 className="text-center text-2xl font-semibold leading-6 text-gray-900">
                        Log Out?
                      </h3>
                      <Typography className="text-center py-2 text-sm font-normal text-[#7E7E7E]">
                        Are you certain you wish to proceed with logging out?
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center py-3">
                    <Button
                      type="button"
                      className="capitalize inline-flex justify-center rounded-xl px-8 py-3 text-sm font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => closeDeleteModal(e)}
                      variant="text"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="ml-3 capitalize inline-flex justify-center rounded-xl border border-transparent bg-primary px-8 py-3 text-sm font-normal text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => handleLogOut(e)}
                    >
                      Yes, Log Out{" "}
                      {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const NavList = () => {
  const location = useLocation();

  const handleRedirect = () => {
    window.location.href = "https://gofixitnow.online/fixer/";
  };

  return (
    <div className="2xl:block xl:block hidden">
      <List className="mt-4 mb-6 p-0 2xl:mt-0 2xl:mb-0 2xl:flex-row 2xl:p-1 xl:mt-0 xl:mb-0 xl:flex-row xl:p-1 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
        <Link
          to="/"
          className={`flex font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/" ? "text-[#ff6a00]" : "text-[#0c0103]"
          }`}
        >
          Home
        </Link>
        <Link
          to="/services"
          className={`flex font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/services" ||
            location.pathname.startsWith("/bookyourservice")
              ? "text-[#ff6a00]"
              : "text-[#0c0103]"
          }`}
        >
          Services
        </Link>
        <Link
          to="/mybooking"
          className={`font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/mybooking"
              ? "text-[#ff6a00]"
              : "text-[#0c0103]"
          }`}
        >
          My Booking
        </Link>
        <div
          className={`cursor-pointer text-black font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4`}
          onClick={(e) => handleRedirect()}
        >
          Become a Fixer
        </div>
      </List>
    </div>
  );
};

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [noData, setNodata] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [notificatinCount, setNotificatinCount] = useState(0);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const handleRedirect = () => {
    window.location.href = "http://158.220.84.148/fixer/";
  };

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsDrawerOpen(false)
    );
  }, []);

  const getNotificationList = (page_no) => {
    const params = {
      pageNo: page_no,
    };

    setNodata(true);

    getNotificationListApi(params)
      .then((res) => {
        const data = res.data.info;
        const total_page = res.data.totalPage;
        setNotificationList(data);
        setTotalPage(total_page);
        setPageNo(page_no);
        setNodata(false);
      })
      .catch((err) => {
        console.log("err >>>", err);
        setNodata(false);
      });
  };

  const viewMores = () => {
    if (totalPage !== pageNo) {
      getNotificationList(pageNo + 1);
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("payload ????", payload);

      const notification_count = payload.data.unreadnotificationCount;
      setNotificatinCount(notification_count);
      getNotificationList(pageNo);
    });

    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "background-message") {
        const { payload } = event.data;
        console.log("payload ????", payload);
      }
    });
  }, []);

  return (
    <>
      <nav className="bg-white w-full fixed z-[10] top-0 border-b border-primary">
        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 2xl:py-3.5 xl:py-3.5 py-2.5">
          <div className="flex items-center justify-between 2xl:h-16 xl:h-16 lg:h-16 h-14">
            <Typography
              as="a"
              href="/services"
              variant="h6"
              className="2xl:block xl:block hidden flex-shrink-0 lg:flex-1"
            >
              <img
                className="2xl:h-[56px] 2xl:w-[122px] xl:h-[56px] xl:w-[122px] h-12 w-auto"
                src={logosm}
                alt="Workflow"
              />
            </Typography>
            <div className="flex items-center">
              <IconButton
                variant="text"
                color="blue-gray"
                className="2xl:hidden xl:hidden block p-2 my-2"
                onClick={openDrawer}
              >
                {isDrawerOpen ? (
                  <IoClose className="h-6 w-6" strokeWidth={2} />
                ) : (
                  <IoMdMenu className="h-6 w-6" strokeWidth={2} />
                )}
              </IconButton>
              <div className="2xl:hidden xl:hidden lg:hidden md:hidden block ml-3 w-full border-2 border-[#E7E7E7] rounded-lg flex justify-center items-center 2xl:px-6 xl:px-5 md:px-4 px-2 py-2.5">
                <img alt="location" src={locationimg} className="w-5 h-5"></img>
                <h6 className="text-sm font-medium ml-2">London</h6>
              </div>
            </div>
            <div className="gap-2 flex item-center">
              <div className="gap-2 2xl:flex xl:flex lg:flex items-center">
                <div className="2xl:block xl:block hidden lg:flex 2xl:flex xl:flex">
                  <NavList />
                </div>
              </div>
              <div>
                <Popover className="relative z-20">
                  <>
                    <Popover.Button className="outline-0 relative p-2 my-2 rounded-full border-2 border-[#EAEAEA] cursor-pointer">
                      <div
                        className="cursor-pointer flex relative"
                        onClick={() => getNotificationList(pageNo)}
                      >
                        {notificatinCount > 0 ? (
                          <div className="z-10 absolute -top-1 right-0 flex items-center justify-center h-4 w-4 -mt-0.5 -mr-0.5 text-xs font-medium text-white rounded-full bg-primary">
                            {notificatinCount}
                          </div>
                        ) : (
                          ""
                        )}
                        <Bell className="2xl:h-6 2xl:w-6 h-5 w-5 text-black" />
                      </div>
                    </Popover.Button>
                    <Popover.Panel
                      id="notifyside"
                      className="2xl:w-[350px] xl:w-[350px] lg:w-[350px] md:w-[350px] w-[300px] h-[500px] overflow-x-hidden p-6 mt-6"
                    >
                      <div className="flex items-center justify-between fontNew">
                        <div className="font-semibold text-xl">
                          Notifications
                        </div>
                      </div>
                      <div className="mt-4">
                        {notificationList.map((item, index) => {
                          return (
                            <div key={index} className="border-b">
                              <div className="flex items-center gap-4 py-2 pl-2 pr-8">
                                <Avatar
                                  size="md"
                                  variant="circular"
                                  alt="tania andrew"
                                  crossOrigin="anonymous"
                                  src={
                                    item.notificationbydetails.profilePic
                                      ? IMAGE_URL +
                                        item.notificationbydetails.profilePic
                                      : noImg
                                  }
                                />
                                <div className="flex flex-col gap-1">
                                  <Typography
                                    variant="small"
                                    color="gray"
                                    className="font-semibold"
                                  >
                                    {item.notificationText}
                                  </Typography>
                                  <Typography className="flex items-center gap-1 text-sm font-medium text-[#9C9DA9]">
                                    <ReactTimeAgo
                                      date={Date.parse(item.updatedAt)}
                                      locale="en-US"
                                    />
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center mt-8">
                        <InfiniteScroll
                          dataLength={notificationList.length || 0}
                          next={viewMores}
                          hasMore={hasMoreData}
                          loader={
                            noData ? (
                              <ThreeDots
                                width={40}
                                height={10}
                                color="#000"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                visible={true}
                                wrapperClass=""
                              />
                            ) : (
                              ""
                            )
                          }
                          scrollableTarget="notifyside"
                        />
                      </div>
                    </Popover.Panel>
                  </>
                </Popover>
              </div>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </nav>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="drawer">
        <Card color="transparent" shadow={false} className="h-screen p-4">
          <div className="mb-2 p-4 flex justify-between items-center">
            <img
              className="2xl:h-[56px] 2xl:w-[122px] xl:h-[56px] xl:w-[122px] h-12 w-auto"
              src={logosm}
              alt="Workflow"
            />
            <div onClick={(e) => setIsDrawerOpen(false)}>
              <IoClose className="h-6 w-6 stroke-2" />
            </div>
          </div>
          <List>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/"
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/");
                setIsDrawerOpen(false);
              }}
            >
              Home
            </ListItem>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/services" ||
                location.pathname.startsWith("/bookyourservice")
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/services");
                setIsDrawerOpen(false);
              }}
            >
              Services
            </ListItem>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/mybooking"
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/mybooking");
                setIsDrawerOpen(false);
              }}
            >
              My Bookings
            </ListItem>
            <ListItem
              className={`my-1 text-black font-medium text-base cursor-pointer p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 `}
              onClick={(e) => handleRedirect()}
            >
              Become a Fixer
            </ListItem>
          </List>
        </Card>
      </Drawer>
    </>
  );
};

export default TopBar;
