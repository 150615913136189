import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CalendarAndTimePicker from "../../components/DateTimePicker/DateTimePicker.js";
import { IMAGE_URL } from "../../services/Api";
import he from "he";
import { FaUpload } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import {
  addBookingServiceApi,
  listAreaApi,
  listCityApi,
  listCountryApi,
} from "../../services/ApiServices.jsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MdClose } from "react-icons/md";
import noImage from "../../assets/images/no-image.png";
import fileImg from "../../assets/images/icons/file.png";
import moment from "moment";
import toast from "react-hot-toast";
import { Button, Dialog, DialogBody, Spinner } from "@material-tailwind/react";
import * as animationData from "../../assets/images/json/success.json";
import Lottie from "react-lottie";

const ServiceRequestForm = () => {
  const location = useLocation();

  var service_details = "";
  try {
    const { data } = location.state;
    service_details = data;
  } catch (e) {
    //id not found
  }

  //   console.log("service_details >>>", service_details);

  const navigate = useNavigate();
  const { id } = useParams();
  const animatedComponents = makeAnimated();
  const [step, setStep] = useState(1);
  const [timing, setTiming] = useState("now");
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Yup validation schema for step one
  const stepOneSchema = Yup.object().shape({
    requiredJob: Yup.string().required("Please Describe Your required job"),
    description: Yup.string().required("Description is required"),
    materials: Yup.string().required("Materials are required"),
    siteAddress: Yup.string().required("Site address is required"),
    files: Yup.mixed()
      .notRequired()
      .test("fileFormat", "Only Image files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(
            value.name.split(".").pop().toLowerCase()
          );
        }
        return true;
      }),
    country: Yup.object()
      .shape({
        label: Yup.string().required("Country is required"),
        value: Yup.string().required("Country is required"),
      })
      .nullable()
      .required("Country is required"),
    city: Yup.object().shape({
      label: Yup.string().required("City is required"),
      value: Yup.string().required("City is required"),
    }),
    area: Yup.object()
      .shape({
        label: Yup.string().required("Area is required"),
        value: Yup.string().required("Area is required"),
      })
      .nullable()
      .required("Area is required"),
  });

  function convertToTimeFormat(timeString) {
    return moment(timeString, "h:mm A").format("HH:mm:ss");
  }

  // Yup validation schema for step two
  const stepTwoSchema = Yup.object().shape({
    selectedDate: Yup.date().required("Date is required"),
    selectedTime: Yup.string().required("Time is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      requiredJob: "",
      description: "",
      materials: "",
      siteAddress: "",
      files: "",
      selectedDate: null,
      selectedTime: null,
      country: null,
      city: null,
      area: null,
    },
    validationSchema: step === 1 ? stepOneSchema : stepTwoSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { resetForm }) => {
      console.log("values >>>", values);

      if (step === 1 && timing === "later") {
        setStep(2);
      } else {
        const subservice = service_details?.subServices
          .map((item) => item.serviceId)
          .toString();
        console.log("Form submitted:", values);
        console.log("service_details >>>", service_details);
        console.log("subservice >>", subservice);

        // navigate("/mybooking");
        if (timing === "later") {
          setLoader(true);
          const date = moment(values.selectedDate).format("YYYY-MM-DD");
          const formattedTime = convertToTimeFormat(values.selectedTime);

          console.log("formattedTime >>>>", formattedTime);

          var formdata = new FormData();
          formdata.append("serviceId", id);
          formdata.append("subServiceId", subservice);
          formdata.append("requiredJob", values.requiredJob);
          formdata.append("requiredMaterials", values.materials);
          formdata.append("description", values.description);
          formdata.append("country", values.country.label);
          formdata.append("countryId", values.country.value);
          formdata.append("city", values.city.label);
          formdata.append("cityId", values.city.value);
          formdata.append("area", values.area.label);
          formdata.append("areaId", values.area.value);
          formdata.append("address", values.siteAddress);
          formdata.append("workingImage", values.files);
          formdata.append("bookingDate", date);
          formdata.append("bookingTime", formattedTime);

          addBookingServiceApi(formdata)
            .then((res) => {
              const message = res.data.message;
              handleOpen();
              toast.success(message || "Create your account Successfully!!!");
              setLoader(false);
              resetForm();
            })
            .catch((err) => {
              const message = err.response.data.message;
              setLoader(false);
              console.log("err >>>", err);
              toast.error(message || "Somthing went wrong!!");
            });
        } else {
          setLoader(true);
          const todays = new Date();
          const date = moment(todays).format("YYYY-MM-DD");
          const time = moment(todays).format("hh:mm:ss");

          var formdata = new FormData();
          formdata.append("serviceId", id);
          formdata.append("subServiceId", subservice);
          formdata.append("requiredJob", values.requiredJob);
          formdata.append("requiredMaterials", values.materials);
          formdata.append("description", values.description);
          formdata.append("country", values.country.label);
          formdata.append("countryId", values.country.value);
          formdata.append("city", values.city.label);
          formdata.append("cityId", values.city.value);
          formdata.append("area", values.area.label);
          formdata.append("areaId", values.area.value);
          formdata.append("address", values.siteAddress);
          formdata.append("workingImage", values.files);
          formdata.append("bookingDate", date);
          formdata.append("bookingTime", time);

          addBookingServiceApi(formdata)
            .then((res) => {
              const message = res.data.message;
              handleOpen();

              toast.success(message || "Create your account Successfully!!!");
              setLoader(false);
              resetForm();
            })
            .catch((err) => {
              const message = err.response.data.message;
              setLoader(false);
              console.log("err >>>", err);
              toast.error(message || "Somthing went wrong!!");
            });
        }
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    navigate("/services");
  };

  const getCountryInfo = () => {
    listCountryApi()
      .then((res) => {
        const data = res.data.info;
        setCountryData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getCountryInfo();
  }, []);

  const getCityInfo = () => {
    const params = {
      countryId: formik?.values?.country?.value,
    };

    listCityApi(params)
      .then((res) => {
        const data = res.data.info;
        setCityData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    if (formik?.values?.country?.value) {
      getCityInfo();
    }
  }, [formik?.values?.country?.value]);

  const getAreaInfo = () => {
    const params = {
      cityId: formik?.values?.city?.value,
    };
    listAreaApi(params)
      .then((res) => {
        const data = res.data.data;
        setAreaList(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    if (formik?.values?.city?.value) {
      getAreaInfo();
    }
  }, [formik?.values?.city?.value]);

  const country_options = countryData.map((item) => {
    return {
      label: item.country,
      value: item.countryId,
    };
  });

  const city_options = cityData.map((item) => {
    return {
      label: item.city,
      value: item.cityId,
    };
  });

  const area_options = areaList.map((item) => {
    return {
      label: item.area,
      value: item.areaId,
    };
  });

  //   const handleFileUpload = (event) => {
  //     const files = Array.from(event.target.files);
  //     const newFiles = files.filter(
  //       (file) =>
  //         !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
  //     );

  //     const filePreviews = newFiles.map((file) => {
  //       return new Promise((resolve) => {
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           resolve({
  //             name: file.name,
  //             type: file.type,
  //             preview: e.target.result,
  //           });
  //         };
  //         reader.readAsDataURL(file);
  //       });
  //     });

  //     Promise.all(filePreviews).then((previews) => {
  //       setUploadedFiles((prev) => [...prev, ...previews]);
  //       formik.setFieldValue("files", [...formik.values.files, ...newFiles]);
  //     });
  //   };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      setUploadedFiles(file);
      formik.setFieldValue("files", file);
    }
  };

  const removeUploadedFile = (fileName) => {
    // setUploadedFiles((prev) => prev.filter((file) => file.name !== fileName));
    // formik.setFieldValue(
    //   "files",
    //   formik.values.files.filter((file) => file.name !== fileName)
    // );
    setUploadedFiles({});
    formik.setFieldValue("files", "");
  };

  return (
    <div>
      <div className="service-request-form-page">
        <section className="points-and-service-request-form-section">
          <h2 className="py-3 text-center 2xl:text-3xl xl:text-3xl text-2xl font-medium">
            Service Request Form
          </h2>
          <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 image-col">
                <form
                  id="service-request-form"
                  onSubmit={formik.handleSubmit}
                  className="service-request-form form"
                >
                  {step === 1 && (
                    <div className="step" id="step-one">
                      <h5 className="text-center mb-4 text-lg font-medium">
                        Enter Details
                      </h5>
                      <div className="selected-category-image-and-describe-the-job-textarea-container flex gap-[15px] mb-4">
                        <div className="bg-[#2b426e] border-[#ff6a00] rounded-md p-2 flex items-center gap-2">
                          <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center justify-center">
                            <img
                              id="selected-category-image"
                              src={IMAGE_URL + service_details?.serviceIcon}
                              alt="Selected Category"
                              className="2xl:max-w-[90px] 2xl:w-[60px] xl:max-w-[90px] xl:w-[60px] lg:max-w-[90px] lg:w-[60px] max-w-[80px] w-[80px]"
                              crossOrigin="anonymous"
                            />
                          </div>
                          <p className="text-white text-xs font-light">
                            {service_details.serviceName
                              ? he.decode(service_details.serviceName)
                              : "-"}
                          </p>
                        </div>
                        <div className="describe-the-job-textarea-container w-full">
                          <textarea
                            name="requiredJob"
                            className={`focus:outline-none py-3 px-4 block min-w-full w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                              formik.touched.requiredJob &&
                              formik.errors.requiredJob
                                ? "invalid"
                                : "border-primary"
                            }`}
                            placeholder="Describe the job needs doing"
                            value={formik.values.requiredJob}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            rows={2}
                          />
                        </div>
                      </div>

                      {/* Upload file component */}
                      <div className="upload-image-option-box-and-uploaded-images-box-container-and-heading mb-3">
                        <h6 className="font-medium mb-1">
                          Upload pictures or short video
                        </h6>
                        <div className="upload-image-option-box-and-uploaded-images-box-container flex gap-[15px]">
                          {/* Uploaded file preview */}
                          {formik.values.files ? (
                            <div className="border border-dashed border-2 border-[#D9D9D9] rounded-md p-2 flex justify-center items-center">
                              {uploadedFiles?.type?.split("/")?.pop() ===
                                "jpeg" ||
                              uploadedFiles?.type?.split("/")?.pop() ===
                                "png" ||
                              uploadedFiles?.type?.split("/")?.pop() ===
                                "svg" ||
                              uploadedFiles?.type?.split("/")?.pop() ===
                                "png" ||
                              uploadedFiles?.type?.split("/")?.pop() ===
                                "jpg" ||
                              uploadedFiles?.type?.split("/")?.pop() ===
                                "gif" ? (
                                <div className="m-2 relative">
                                  <img
                                    alt="document"
                                    className="rounded-lg h-[100px] w-full"
                                    src={
                                      uploadedFiles
                                        ? URL.createObjectURL(uploadedFiles)
                                        : noImage
                                    }
                                  />
                                  <button
                                    className="absolute -right-2 -top-1 bg-red-700 rounded-full close-btn"
                                    onClick={removeUploadedFile}
                                    type="button"
                                  >
                                    <MdClose
                                      className="font-bold"
                                      color="#FFFFFF"
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className="cursor-pointer image-fit">
                                  <div className="m-2 relative">
                                    <img
                                      alt="document"
                                      className="rounded-lg h-20 w-20"
                                      src={fileImg}
                                    />
                                    <button
                                      className="absolute -right-2 -top-1 bg-red-700 rounded-full close-btn"
                                      onClick={removeUploadedFile}
                                      type="button"
                                    >
                                      <MdClose className="w-4 h-4 font-bold" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              className={`upload-image-option-box relative ${
                                formik.touched.files && formik.errors.files
                                  ? "invalid"
                                  : ""
                              }`}
                            >
                              <div className="bg-[#2b426c] rounded-full p-4">
                                <FaUpload fontSize={30} color="#FFFFFF" />
                              </div>
                              <input
                                type="file"
                                id="visibility-hidden"
                                className="absolute top-0 left-0 w-full h-full opacity-0 upload-file-field"
                                onChange={handleFileUpload}
                                //   multiple
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Materials and Site Address */}
                      <input
                        type="text"
                        className={`mb-3 ${
                          formik.touched.materials && formik.errors.materials
                            ? "invalid"
                            : ""
                        }`}
                        placeholder="Do you need any materials?"
                        name="materials"
                        value={formik.values.materials}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <input
                        type="text"
                        className={`mb-3 ${
                          formik.touched.siteAddress &&
                          formik.errors.siteAddress
                            ? "invalid"
                            : ""
                        }`}
                        placeholder="Enter Site Address"
                        name="siteAddress"
                        value={formik.values.siteAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <div className="describe-the-job-textarea-container w-full">
                        <textarea
                          name="description"
                          className={`focus:outline-none py-3 px-4 block min-w-full w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            formik.touched.description &&
                            formik.errors.description
                              ? "invalid"
                              : "border-primary"
                          }`}
                          placeholder="Brief description of required work"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={2}
                        />
                      </div>

                      <div className="mt-3">
                        <Select
                          components={animatedComponents}
                          options={country_options}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          name="country"
                          value={formik.values.country}
                          onChange={(value) =>
                            formik.setFieldValue("country", value)
                          }
                          onBlur={() => formik.setFieldTouched("country", true)}
                          placeholder="Select Country"
                          className={`custom-select ${
                            formik.touched.country && formik.errors.country
                              ? "invalid"
                              : ""
                          }`}
                        />
                      </div>

                      <div className="mt-3">
                        <Select
                          components={animatedComponents}
                          options={city_options}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          name="city"
                          value={formik.values.city}
                          onChange={(value) =>
                            formik.setFieldValue("city", value)
                          }
                          onBlur={() => formik.setFieldTouched("city", true)}
                          placeholder="Select City"
                          className={`custom-select ${
                            formik.touched.city && formik.errors.city
                              ? "invalid"
                              : ""
                          }`}
                        />
                      </div>

                      <div className="mt-3">
                        <Select
                          components={animatedComponents}
                          options={area_options}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          name="area"
                          value={formik.values.area}
                          onChange={(value) =>
                            formik.setFieldValue("area", value)
                          }
                          onBlur={() => formik.setFieldTouched("area", true)}
                          placeholder="Select Area"
                          className={`custom-select ${
                            formik.touched.area && formik.errors.area
                              ? "invalid"
                              : ""
                          }`}
                        />
                      </div>

                      {/* Timing selection */}
                      <div className="my-4 flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between gap-3">
                        <div className="radio-buttons">
                          <p className="mb-2 w-64">
                            When do you require the service?
                          </p>
                          <div className="flex gap-2">
                            <label className="custom-radio-container">
                              <input
                                type="radio"
                                name="timing"
                                value="now"
                                checked={timing === "now"}
                                onChange={(e) => setTiming(e.target.value)}
                              />
                              <span className="custom-radio"></span>
                              Now
                            </label>
                            <label className="custom-radio-container">
                              <input
                                type="radio"
                                name="timing"
                                value="later"
                                checked={timing === "later"}
                                onChange={(e) => setTiming(e.target.value)}
                              />
                              <span className="custom-radio"></span>
                              Later
                            </label>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            id="conditional-button"
                            className="bg-primary text-white py-2 w-48 flex justify-center items-center"
                          >
                            {loader && timing === "now" ? (
                              <Spinner className="h-4 w-4 mr-2" />
                            ) : (
                              ""
                            )}
                            {timing === "now" ? "Submit" : "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Step Two */}
                  {step === 2 && (
                    <div className="step" id="step-two">
                      <CalendarAndTimePicker formik={formik} />

                      <div className="flex gap-4 back-and-submit-button">
                        <button
                          type="button"
                          id="back-button"
                          className="border border-primary w-full"
                          onClick={() => setStep(1)}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          id="submit-button"
                          className="bg-primary text-white w-full flex justify-center items-center"
                        >
                          {loader ? <Spinner className="h-4 w-4 mr-2" /> : ""}
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Dialog
        open={open}
        handler={handleOpen}
        size="sm"
        className="rounded-none"
      >
        <DialogBody>
          <div className="my-4 flex justify-center items-center">
            <Lottie options={defaultOptions} height={144} width={144} />
          </div>
          <div className="my-3">
            <h6 className="text-primary font-medium text-center 2xl:text-xl xl:text-xl lg:text-xl text-lg">
              Your Booking Request sent
            </h6>
            <h4 className="text-primary font-semibold text-center text-2xl py-3">
              Successful!
            </h4>
          </div>
          <div className="flex justify-center items-center py-5">
            <Button
              className="text-sm py-3 rounded-xl w-[50%] capitalize font-medium bg-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Done
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default ServiceRequestForm;
