import React, { useState, useEffect } from "react";
import servicebg from "../../assets/images/pages/services/servicesbg.png";
import { Button, IconButton, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import TopBar from "../../layouts/Topbar/Topbar";
// import Header from "../../layouts/Header/Header";
import Header from "../../components/home/Header";
import Footer from "../../layouts/Footer/Footer";
import { listServicesApi } from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import he from "he";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import NewFooter from "../../layouts/NewFooter/NewFooter";

const Services = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const [serviceListData, setServiceListData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [selecteCategory, setSelecteCategory] = useState(null);
  const [totalPage, setTotalPage] = useState(3);
  const [pending, setPending] = useState(false);

  const getServiceListInfo = () => {
    const params = { pageNo: pageNo };
    setPending(true);
    listServicesApi(params)
      .then((res) => {
        const info = res.data.info;
        const total_page = res.data.totalPage;
        setServiceListData(info);
        setTotalPage(total_page);
        setPending(false);
      })
      .catch((err) => {
        console.log("err >>>", err);
        setPending(false);
      });
  };

  useEffect(() => {
    if (token) {
      getServiceListInfo();
    }
  }, [pageNo]);

  const renderPaginationButtonss = () => {
    const maxPagesToShow = 3;
    const buttons = [];
    const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={
            i === pageNo
              ? "px-4 py-[7px] mx-1 bg-[#C2CDFF] rounded-md text-primary"
              : "px-4 py-[7px] mx-1 text-primary"
          }
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const toggleSubservice = (service, subservice) => {
    setSelecteCategory((prev) => {
      if (prev && prev.serviceId === service.serviceId) {
        const subserviceIndex = prev.subServices.findIndex(
          (s) => s.serviceId === subservice.serviceId
        );
        let updatedSubservices;
        if (subserviceIndex >= 0) {
          updatedSubservices = prev.subServices.filter(
            (s) => s.serviceId !== subservice.serviceId
          );
        } else {
          updatedSubservices = [...prev.subServices, subservice];
        }
        return { ...service, subServices: updatedSubservices };
      } else {
        return { ...service, subServices: [subservice] };
      }
    });
  };

  const handleBookService = (service_id) => {
    if (token) {
      navigate(`/bookyourservice/${service_id}`, {
        state: {
          data: selecteCategory,
        },
      });
    } else {
      navigate(`/signin`);
    }
  };

  return (
    <div>
      {token ? <TopBar /> : <Header />}
      <div className="2xl:mt-20 xl:mt-20 lg:mt-22 mt-18 2xl:h-[940px] xl:h-[700px] lg:h-[600px] md:h-[500px] h-[550px]">
        <div
          className="gradient-image-container inset-0"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0)), url(${servicebg})`,
          }}
        >
          <div className="flex justify-center h-full">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 h-full">
              <div className="py-5 2xl:px-8 xl:px-8 lg:px-5 px-5 text-white flex justify-center items-center h-full">
                <div className="my-4 max-w-[791px]">
                  <h1 className="text-center 2xl:text-[60px] xl:text-[40px] lg:text-[30px] text-3xl font-semibold tracking-wider py-4">
                    All Our Services
                  </h1>
                  <div className="py-4">
                    <h6 className="tracking-wide 2xl:text-lg text-base leading-7 font-medium">
                      Pick a service from our bag of tricks, tell us when you
                      fancy it, give a quick job rundown, and toss in any fun
                      pics you've got! Our crack team of Fixers is itching to
                      dive into action, whether it's today or whenever suits
                      your fancy. We're all about top-notch skills, a sprinkle
                      of professionalism, and a whole lot of dedication to wow
                      you with our service!
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 2xl:py-12 xl:py-8 py-8">
        {pending === true ? (
          <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4">
            {[1, 2, 3, 4].map((star) => (
              <div
                className="2xl:p-8 xl:p-8 p-5 service-card-shadow border-[#BFCAFF] border rounded-xl my-5"
                key={star}
              >
                <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-5 md:gap-4 gap-4">
                  <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-3 md:col-span-4">
                    <div className="flex justify-center items-center">
                      <div className="bg-gray-300  rounded-lg 2xl:w-[350px] 2xl:h-[350px] xl:w-[312px] xl:h-[312px] lg:w-[312px] lg:h-[312px] w-full h-full"></div>
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-8 xl:col-span-8 lg:col-span-9 md:col-span-8">
                    <div className="">
                      <div className="py-5 bg-gray-300 rounded-lg"></div>
                      <div className="mt-4 py-2.5 bg-gray-300 rounded-lg"></div>
                      <div className="py-3 grid grid-cols-12 gap-3">
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                        <div
                          className={`bg-gray-300 col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                        >
                          <div className="">
                            <div className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : pending === false && serviceListData.length ? (
          <>
            {serviceListData &&
              serviceListData.map((item, index) => {
                return (
                  <div
                    className="2xl:p-8 xl:p-8 p-5 service-card-shadow border-[#BFCAFF] border rounded-xl my-5"
                    key={index}
                  >
                    <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-5 md:gap-4 gap-4">
                      <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-3 md:col-span-4">
                        <div className="flex justify-center items-center">
                          <img
                            src={IMAGE_URL + item.serviceImage}
                            alt=""
                            className="2xl:w-[350px] 2xl:h-[350px] xl:w-[312px] xl:h-[312px] lg:w-[312px] lg:h-[312px] w-full h-full"
                            crossOrigin="anonymous"
                          ></img>
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-8 xl:col-span-8 lg:col-span-9 md:col-span-8">
                        <div className="">
                          <h3 className="text-primary 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-2xl text-xl font-semibold">
                            {item.serviceName
                              ? he.decode(item.serviceName)
                              : ""}
                          </h3>
                          <div className="py-2">
                            <p className="text-[#363636] tracking-wide">
                              {item.serviceDescription
                                ? he.decode(he.decode(item.serviceDescription))
                                : "-"}
                            </p>
                          </div>
                          <div className="py-3 grid grid-cols-12 gap-3">
                            {item.subServices &&
                              item.subServices.map((category, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`${
                                      selecteCategory &&
                                      selecteCategory.serviceId ===
                                        item.serviceId &&
                                      selecteCategory.subServices.some(
                                        (ss) =>
                                          ss.serviceId === category.serviceId
                                      )
                                        ? "bg-primary border text-white"
                                        : "border-[#C2CDFF] border"
                                    } col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                                    onClick={() =>
                                      toggleSubservice(item, category)
                                    }
                                  >
                                    <div className="">
                                      <img
                                        alt="category_image"
                                        src={IMAGE_URL + category.serviceIcon}
                                        className="2xl:max-w-[40px] 2xl:h-[40px] xl:max-w-12 xl:h-12 lg:max-w-12 md:max-w-10 max-w-10"
                                        crossOrigin="anonymous"
                                      ></img>
                                    </div>
                                    <h6 className="2xl:text-sm xl:text-sm lg:text-sm text-xs font-medium ml-2">
                                      {category.serviceName
                                        ? he.decode(category.serviceName)
                                        : ""}
                                    </h6>
                                  </div>
                                );
                              })}
                          </div>
                          {selecteCategory &&
                          selecteCategory.serviceId === item.serviceId ? (
                            <div className="flex justify-end items-end">
                              <Button
                                className="capitalize font-medium bg-primary text-white px-8 py-3"
                                onClick={(e) =>
                                  handleBookService(item.serviceId)
                                }
                              >
                                Book Request
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {serviceListData.length ? (
              <div className="flex justify-end items-center py-3">
                <IconButton
                  variant="outlined"
                  size="sm"
                  className="rounded-full border-[#BFCAFF]"
                  onClick={() => handlePageChange(pageNo - 1)}
                  disabled={pageNo === 1}
                >
                  <MdOutlineChevronLeft className="w-6 h-6" color="#37489B" />
                </IconButton>
                <div className="flex items-center mx-1">
                  {renderPaginationButtonss()}
                </div>
                <IconButton
                  variant="outlined"
                  size="sm"
                  className="rounded-full border-[#BFCAFF]"
                  onClick={() => handlePageChange(pageNo + 1)}
                  disabled={pageNo === totalPage}
                >
                  <MdOutlineChevronRight className="w-6 h-6" color="#37489B" />
                </IconButton>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <div className="py-5 text-center justify-center items-center flex h-96">
            <h6 className="font-medium text-black">No Service found!!!</h6>
          </div>
        )}
      </div>
      <NewFooter />
    </div>
  );
};

export default Services;
