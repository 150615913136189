import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import { IoMdSearch } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import Header from "../../components/home/Header";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceSection from "./ServiceSections";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import rightArrow from "../../assets/images/icons/arrow-right.png";
import leftArrow from "../../assets/images/icons/arrow-left.png";
import guideimg1 from "../../assets/images/guideimg/select-the-job-category.png";
import guideimg2 from "../../assets/images/guideimg/select-the-service.png";
import guideimg3 from "../../assets/images/guideimg/describe-the-job.png";
import guideimg4 from "../../assets/images/guideimg/immediate-service-or-select-date.png";
import icon1 from "../../assets/images/guideimg/icon1.png";
import icon2 from "../../assets/images/guideimg/icon2.png";
import icon3 from "../../assets/images/guideimg/icon2.png";
import commonservice1 from "../../assets/images/common-services/flatpack-assembly.png";
import commonservice2 from "../../assets/images/common-services/tv-mounting.png";
import commonservice3 from "../../assets/images/common-services/cut-your-lawn.png";
import commonservice4 from "../../assets/images/common-services/putting-up-shelfs.png";
import commonservice5 from "../../assets/images/common-services/painting.png";
import commonservice6 from "../../assets/images/common-services/gas-safety-checks.png";
import commonservice7 from "../../assets/images/common-services/waste-removal.png";
import commonservice8 from "../../assets/images/common-services/home-cleaning-service.png";
import img1 from "../../assets/images/newlanding/fixing-and-repairs.png";
import img2 from "../../assets/images/newlanding/electrical-bulb.png";
import img3 from "../../assets/images/newlanding/plumbing-house.png";
import num1 from "../../assets/images/newlanding/num-1.png";
import num2 from "../../assets/images/newlanding/num-2.png";
import num3 from "../../assets/images/newlanding/num-3.png";
import num4 from "../../assets/images/newlanding/num-4.png";
import chooseusimage from "../../assets/images/newlanding/why-choose-us-image.png";
import NewFooter from "../../layouts/NewFooter/NewFooter";
import TopBar from "../../layouts/Topbar/Topbar";
import {
  listMainServiceApi,
  listReviewApi,
  listSubServiceApi,
} from "../../services/ApiServices";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../services/Api";
import he from "he";
import noImage from "../../assets/images/no-image.png";
import { Helmet } from "react-helmet";

const guideSteps = [
  {
    id: 1,
    imgSrc: guideimg1,
    altText: "Select the job category",
    description: "Select the job category you require",
  },
  {
    id: 2,
    imgSrc: guideimg2,
    altText: "Select the service",
    description: "Then select a service from the category",
  },
  {
    id: 3,
    imgSrc: guideimg3,
    altText: "Describe the job",
    description: "Describe the job and upload some pictures or a video",
  },
  {
    id: 4,
    imgSrc: guideimg4,
    altText: "Select service timing",
    description: "Request immediate service or select a date",
  },
];

const statsData = [
  {
    imgSrc: icon1,
    label: "Total Orders",
    value: "2018002",
    className: "total-orders-container",
  },
  {
    imgSrc: icon2,
    label: "Active Clients",
    value: "718002",
    className: "active-clients-container",
  },
  {
    imgSrc: icon3,
    label: "Team Members",
    value: "21089",
    className: "team-members-container",
  },
];

const servicesData = [
  {
    id: 1,
    imgSrc: commonservice1,
    title: "Flatpack Assembly",
    price: "£30",
  },
  {
    id: 2,
    imgSrc: commonservice2,
    title: "TV Mounting",
    price: "£35",
  },
  {
    id: 3,
    imgSrc: commonservice3,
    title: "Cut Your Lawn",
    price: "£30",
  },
  {
    id: 4,
    imgSrc: commonservice4,
    title: "Putting Up Shelves",
    price: "£30",
  },
  {
    id: 5,
    imgSrc: commonservice5,
    title: "Painting Services",
    price: "£25",
  },
  {
    id: 6,
    imgSrc: commonservice6,
    title: "Gas Safety Checks",
    price: "£60",
  },
  {
    id: 7,
    imgSrc: commonservice7,
    title: "Waste Removal",
    price: "£60",
  },
  {
    id: 8,
    imgSrc: commonservice8,
    title: "Home Cleaning Service",
    price: "£35",
  },
];

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-prev"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      {" "}
      <img src={leftArrow} alt="" className="w-7 h-7"></img>
    </div>
  );
}

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-next"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="" className="w-7 h-7"></img>
    </div>
  );
}

const renderBullet = (index, className) => {
  return `<div class=${className}>
                <span class="line"></span>
            </div>`;
};

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const [activeTab, setActiveTab] = useState();
  const [showServices, setShowServices] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [subServiceData, setSubServiceData] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const listMainServiceInfo = () => {
    setLoader(true);

    listMainServiceApi()
      .then((res) => {
        const info = res.data.info;
        const default_sub_service = info[0].serviceId;
        setServiceData(info);
        setActiveTab(info[0]?.serviceId);
        setSelectedCategory(info[0]);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err >>>", err);
      });
  };

  const listReviewInfo = () => {
    listReviewApi()
      .then((res) => {
        const data = res.data.info;
        setReviewList(data);
      })
      .catch((err) => {
        console.log("err ???", err);
      });
  };

  useEffect(() => {
    listMainServiceInfo();
    listReviewInfo();
  }, []);

  const listSubServiceInfo = () => {
    const params = {
      parentId: activeTab,
    };
    listSubServiceApi(params)
      .then((res) => {
        const info = res.data.info;
        setSubServiceData(info);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    if (activeTab) {
      listSubServiceInfo();
    }
  }, [activeTab]);

  const handleTabClick = (categoryId, category) => {
    setActiveTab(categoryId);
    setShowServices(true);
    setSelectedCategory(category);
  };

  const handleBackClick = () => {
    setShowServices(false);
    setActiveTab("");
  };

  //   useEffect(() => {
  //     if (activeTab) {
  //       const activeTabString = String(activeTab);
  //       const escapedTab = activeTabString.replace(
  //         /^[0-9]/,
  //         (digit) => `\\3${digit} `
  //       );
  //       const contentElement = document.querySelector(`.${escapedTab}-content`);
  //       const onDemandSection = document.getElementById(
  //         `${activeTabString}-on-demand-services-section`
  //       );

  //       document.querySelectorAll(".tab-content").forEach((content) => {
  //         content.classList?.remove("active");
  //       });

  //       if (contentElement) {
  //         contentElement.classList.add("active");
  //       }

  //       document.querySelectorAll(".on-demand-section").forEach((section) => {
  //         section.classList?.add("d-block");
  //       });

  //       if (onDemandSection) {
  //         onDemandSection.classList.remove("d-none");
  //         onDemandSection.classList.add("d-block");
  //       }
  //     }
  //   }, [activeTab]);

  const renderCategoryTabs = () =>
    serviceData &&
    serviceData.map((category) => {
      return (
        <li
          key={category.serviceId}
          className={`tab ${activeTab === category.serviceId ? "active" : ""}`}
          onClick={() => handleTabClick(category.serviceId, category)}
        >
          <div className="image-and-label">
            <img
              src={IMAGE_URL + category.serviceIcon}
              alt={category.serviceName}
              crossOrigin="anonymous"
              className=""
            />
            {category.serviceName ? he.decode(category.serviceName) : "-"}
          </div>
        </li>
      );
    });

  const renderLgCategoryTabs = () =>
    serviceData.map((category) => {
      return (
        <li
          key={category.serviceId}
          onClick={() => handleTabClick(category.serviceId, category)}
        >
          <div
            className={`tab 2xl:mx-0 xl:mx-0 mx-2 ${
              activeTab === category.serviceId ? "active" : ""
            }`}
          >
            <div className="image-and-label">
              <img
                src={IMAGE_URL + category.serviceIcon}
                alt={category.serviceName}
                crossOrigin="anonymous"
                className="w-14 h-14"
              />
              <h5 className="text-white font-sm break-word my-1">
                {category.serviceName ? he.decode(category.serviceName) : "-"}
              </h5>
            </div>
          </div>
        </li>
      );
    });

  const renderServiceSections = () =>
    serviceData.map((section) => {
      return (
        <ServiceSection
          key={section.serviceId}
          data={section}
          activeTab={activeTab}
        />
      );
    });

  var settings = {
    dots: true,
    speed: 500,
    infinite: false,
    centerPadding: "20px",
    slidesToShow: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const handleBookNow = (e, item) => {
    const obj = {
      ...selectedCategory,
      subServices: [item],
    };

    if (!token) {
      navigate("/signin");
    } else {
      navigate(`/servicesrequest/${activeTab}`, {
        state: {
          data: obj,
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Home - Go Fix It Now</title>
      </Helmet>
      {token ? <TopBar /> : <Header />}
      <main>
        <div className="home-page">
          <div className="hero-section">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 hero-text px-5 w-full">
              <div className="hero-left-text-content ">
                <h1 className="title title-2">
                  Go <span>Fix</span> It Now
                </h1>
                <h4 className="mb-0 desc text-xl">
                  Same Day Home Repair Service
                </h4>
                <div className="location-and-search-container">
                  <div className="location-container">
                    <FaLocationDot color="#ff6a00" fontSize={24} />
                    <span className="location">London</span>
                  </div>
                  <form className="search-wrapper cf" method="post">
                    <input
                      type="text"
                      name="email_address"
                      placeholder="What service do you need?"
                      style={{ boxShadow: "none" }}
                      required
                    />
                    <button type="submit">
                      <IoMdSearch fontSize={22} />
                    </button>
                  </form>
                </div>
              </div>
              <div className="hero-right-text-content">
                <h2 className="text-2xl font-medium">
                  Book a <span>service</span> with <br /> one of our fixers now
                </h2>
              </div>
            </div>
          </div>

          <section className="services-categories services-categories-bydefault">
            {loader ? (
              <div className="main-container flex items-center py-6">
                <div className="w-full">
                  <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4 flex justify-center flex-wrap gap-2">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((star) => (
                      <div
                        key={star}
                        className="service-card-shadow rounded-xl"
                      >
                        <div className="flex justify-center items-center">
                          <div className="bg-gray-300 rounded-lg w-[100px] h-[140px]"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="my-6">
                    <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4 flex justify-center flex-wrap gap-2">
                      {[1, 2, 3, 4, 5, 6].map((star) => (
                        <div
                          key={star}
                          className="service-card-shadow rounded-xl"
                        >
                          <div className="flex justify-center items-center">
                            <div className="bg-gray-300 rounded-lg w-[160px] h-[100px]"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!showServices && (
                  <div className="2xl:hidden xl:hidden lg:hidden md:hidden block">
                    <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 flex justify-center">
                      <div className="tabs-wrapper-and-section-heading">
                        <h4 className="section-title text-center font-medium text-2xl">
                          Select A <span>Category</span>
                        </h4>
                        <div className="wrapper tabs-wrapper">
                          <div>
                            <ul className="tabs-box p-0 flex justify-center items-center w-full">
                              {renderCategoryTabs()}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {showServices && (
                  <div className="2xl:hidden xl:hidden lg:hidden md:hidden block">
                    <div className="mobile-category-view px-4 ">
                      <h4 className="select-a-service-title text-center font-medium text-2xl">
                        Select A <span>Service</span>
                      </h4>
                      <button
                        className="bg-primary text-white px-3 py-1.5 rounded-md my-1"
                        onClick={handleBackClick}
                      >
                        Back
                      </button>
                      <div
                        className={`tab-content tab-content-for-desktop ${activeTab}-content`}
                      >
                        <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 my-3">
                          <div className="grid grid-cols-12 gap-2">
                            {subServiceData.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2 col-span-4"
                                >
                                  <div
                                    onClick={(e) => handleBookNow(e, item)}
                                    className="flex cursor-pointer 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap flex-wrap justify-center items-center text-center bg-[#2b426e] text-white 2xl:rounded-md xl:rounded-md lg:rounded-md rounded-xl p-2 h-full  max-w-[160px]"
                                  >
                                    <img
                                      src={IMAGE_URL + item.serviceIcon}
                                      alt={item.serviceName}
                                      className="w-12 h-12"
                                      crossOrigin="anonymous"
                                    />
                                    <h5 className="text-[11px] font-light break-words tracking-wider">
                                      {item.serviceName
                                        ? he.decode(item.serviceName)
                                        : "-"}
                                    </h5>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="2xl:block xl:block lg:block md:block hidden">
                  <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 flex justify-center">
                    <div className="tabs-wrapper-and-section-heading w-full">
                      <h4 className="section-title text-center font-medium text-2xl">
                        Select A <span>Category</span>
                      </h4>
                    </div>
                  </div>
                  <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 flex justify-center">
                    <div className="2xl:w-[1070px] xl:w-[1070px] lg:w-[1000px] md:w-[750px] w-full">
                      <div className="slider-container category-slider px-6 w-full">
                        <ul className="tabs-box p-0">
                          <Slider {...settings}>
                            {renderLgCategoryTabs()}
                          </Slider>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h4 className="select-a-service-title font-medium text-2xl">
                    Select A <span>Service</span>
                  </h4>
                  <div
                    className={`tab-content tab-content-for-desktop ${activeTab}-content`}
                  >
                    <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4 flex justify-center mx-auto">
                      {subServiceData.length ? (
                        <div className="grid grid-cols-12 gap-3">
                          {subServiceData &&
                            subServiceData.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2 col-span-4"
                                >
                                  <div
                                    onClick={(e) => handleBookNow(e, item)}
                                    className="cursor-pointer flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap flex-wrap justify-center items-center text-center bg-[#2b426e] text-white 2xl:rounded-md xl:rounded-md lg:rounded-md rounded-xl p-2 h-full 2xl:max-w-[160px] xl:max-w-[160px]"
                                  >
                                    <img
                                      src={IMAGE_URL + item.serviceIcon}
                                      alt={item.serviceName}
                                      className="2xl:w-16 2xl:h-20 xl:w-16 xl:h-16 w-14 h-14"
                                      crossOrigin="anonymous"
                                    />
                                    <h5 className="text-[11px] font-light break-words tracking-wider my-2">
                                      {item.serviceName
                                        ? he.decode(item.serviceName)
                                        : "-"}
                                    </h5>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <div className="h-48">
                          <div className="flex justify-center items-center h-full">
                            <h6 className="font-medium">No Service Found</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {renderServiceSections()}
              </>
            )}
          </section>

          <section className="quick-guide-section">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
              <h3 className="title mb-5 2xl:text-[1.75rem] xl:text-[1.75rem] lg:text-2xl text-2xl font-bold leading-8">
                Quick <span>guide</span> on selecting our services
              </h3>
              <div className="grid grid-cols-12 gap-2 py-4">
                {guideSteps.map((step, index) => (
                  <div
                    className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 col-span-6 mb-3 mb-md-0 step relative"
                    key={index}
                  >
                    <span className="number">{index + 1}</span>
                    <img
                      src={step.imgSrc}
                      alt={step.altText}
                      className="2xl:w-[200px] xl:w-[180px] lg:w-[160px] md:w-[140px] w-[100px]"
                    />
                    <h5 className="text-center 2xl:text-xl xl:text-lg md:text-lg text-base font-medium leading-5">
                      {step.description}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <div className="orders-clients-team-section">
            <div className="main-container">
              <div className="grid grid-cols-12">
                {statsData.map((stat, index) => (
                  <div
                    className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4"
                    key={index}
                  >
                    <div className={`${stat.className} fact `}>
                      <div className="image">
                        <img
                          src={stat.imgSrc}
                          alt={stat.label}
                          className="w-full h-full"
                        />
                      </div>
                      <div className="content">
                        <p
                          className={stat.label
                            .toLowerCase()
                            .replace(/ /g, "-")}
                        >
                          {stat.label}
                        </p>
                        <div>
                          <p
                            className={`${stat.label
                              .toLowerCase()
                              .replace(/ /g, "-")}-numbers`}
                          >
                            {stat.value}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <section className="most-common-services-section for-mobile">
            <div className="most-common-services-container 2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10">
              <h3 className="title mb-5 2xl:text-[1.75rem] xl:text-[1.75rem] lg:text-2xl font-bold leading-8">
                Most <span>Common</span> Services
              </h3>
              <div className="grid grid-cols-12 gap-2">
                {servicesData.map((service, index) => (
                  <div
                    className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-6 2xl:mb-4 xl:mb-4 lg:mb-4 :mb-4"
                    key={index}
                  >
                    <div className="most-common-service-box">
                      <img
                        src={service.imgSrc}
                        alt={`${service.title} image`}
                        className="md:w-full main-image"
                      />
                      <div className="title-and-button">
                        <h5 className="title">
                          {service.title}
                          <br /> From {service.price}
                        </h5>
                        <div className="button-container flex justify-end">
                          <button className="btn py-2 px-3 rounded-md hover:shadow-lg">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="why-chose-us-section why-chose-us-section-1">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 mx-4">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <div className="feature h-full">
                    <img src={img1} alt="" />
                    <div className="para-and-read-more-link-container">
                      <p className="full-content 2xl:max-w-[360px] xl:max-w-[360px] lg:max-w-[360px]">
                        We offer a wide range of services including shelf
                        installation, TV mounting, fixture fitting, flooring
                        installation, and door hanging. Our skilled team
                        delivers exceptional results, and we welcome you to
                        schedule a service with us to experience our expertise.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <div className="feature h-full">
                    <img src={img2} alt="" />
                    <div className="para-and-read-more-link-container">
                      <p className="full-content 2xl:max-w-[360px] xl:max-w-[360px] lg:max-w-[360px]">
                        Fixing light switches, wiring homes, installing outlets,
                        repairing wiring, upgrading panels, inspecting and
                        testing electrical systems are common electrical tasks.
                        Prompt service is available for all needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4">
                  <div className="feature h-full">
                    <img src={img3} alt="" />
                    <div className="para-and-read-more-link-container">
                      <p className="full-content 2xl:max-w-[360px] xl:max-w-[360px] lg:max-w-[360px]">
                        If you require professional plumbing services for
                        various issues like burst pipes, boiler malfunctions,
                        installations, blocked drains, leaky pipes, or low water
                        pressure. Go Fix It Now can help with just a click.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="why-choose-us-section why-choose-us-section-2">
            <div className="why-choose-us-container container">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12 2xl:col-span-7 xl:col-span-7 lg:col-span-7 md:col-span-6">
                  <h3 className="section-title text-left mb-4 text-[1.75rem] font-bold">
                    Why <span>Choose</span> Us{" "}
                  </h3>
                  <div className="grid grid-cols-12 2xl:gap-2 xl:gap-2 lg:gap-2 gap-6">
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                      <div className="point-box h-full">
                        <img
                          src={num1}
                          alt="number 1"
                          className="number-image"
                        />
                        <h5 className="title text-xl font-semibold">
                          ID-checked Fixers
                        </h5>
                        <p className="desc mb-0 text-base">
                          Fixers are always identity-checked before joining the
                          platform.
                        </p>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                      <div className="point-box h-full">
                        <img
                          src={num2}
                          alt="number 2"
                          className="number-image"
                        />
                        <h5 className="title text-xl font-semibold">
                          Dedicated Support
                        </h5>
                        <p className="desc mb-0 text-base">
                          Friendly service when you need us every day of the
                          week.
                        </p>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                      <div className="point-box h-full">
                        <img
                          src={num3}
                          alt="number 3"
                          className="number-image"
                        />
                        <h5 className="title text-xl font-semibold">
                          Same-Day Service
                        </h5>
                        <p className="desc mb-0 text-base">
                          Our fixers are on hand and ready to assist you with a
                          moments notice.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                      <div className="point-box h-full">
                        <img
                          src={num4}
                          alt="number-4"
                          className="number-image"
                        />
                        <h5 className="title text-xl font-semibold">
                          Health & Safety Assured
                        </h5>
                        <p className="desc mb-0 text-base">
                          Fixers are PPE protected and always clear up before
                          leaving
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-5 xl:col-span-5 lg:col-span-5 md:col-span-6 image-col">
                  <img
                    src={chooseusimage}
                    alt=""
                    className="main-image w-full h-full"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="testimonial_wrap">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 px-5">
              <div className="title-and-des-container">
                <h3 className="title text-[1.75rem]">
                  See What Our <span>Clients</span> Say About Our Fixers
                </h3>
              </div>
              {reviewList.length ? (
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={25}
                  pagination={{
                    clickable: true,
                    el: ".testimonial_wrap .swiper .swiper-custom-pagination",
                    renderBullet,
                  }}
                  autoplay={{ delay: 3000 }}
                  breakpoints={{
                    0: { slidesPerView: 1, spaceBetween: 20 },
                    576: { slidesPerView: 2, spaceBetween: 15 },
                    768: { slidesPerView: 3, spaceBetween: 15 },
                  }}
                  className="swiper"
                >
                  {reviewList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="item h-full">
                          <div className="profile-image-name-and-stars-container flex gap-2 mb-2">
                            <img
                              src={
                                item?.review_by
                                  ? IMAGE_URL + item?.review_by?.profilePic
                                  : noImage
                              }
                              alt="proile-img"
                              className="w-20 h-20 object-cover object-fit rounded-md"
                              crossOrigin="anonymous"
                            />
                            <div className="name-and-stars-container flex flex-col justify-center">
                              <h6 className="font-medium">
                                {`${item?.review_by?.firstName} ${item?.review_by?.lastName}`}
                              </h6>
                              <div className=" flex items-center gap-0">
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    className="cursor-pointer"
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        item.reviewStar >= star
                                          ? "#FFC107"
                                          : "#D7D7D7",
                                      fontSize: "25px",
                                      padding: "0px",
                                    }}
                                  >
                                    {" "}
                                    ★{" "}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="review">
                            <p className="mb-0">{item.reviewText || ""}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <div className="swiper-custom-pagination"></div>
                </Swiper>
              ) : (
                <div className="h-48">
                  <div className="flex justify-center items-center h-full">
                    <h6 className="font-medium">Reviews Not Found</h6>
                  </div>
                </div>
              )}
            </div>
          </section>

          <section className="areas-we-cover-section">
            <div className="2xl:mx-32 xl:mx-20 lg:mx-14 md:mx-10 px-5">
              <h3 className="section-title text-[1.75rem]">
                Areas We <span>Cover</span>
              </h3>
              <p>
                London, Birmingham, Glasgow, Liverpool, Bristol, Manchester,
                Sheffield, Leeds, Edinburgh, Leicester, Coventry, Bradford,
                Cardiff, Belfast, Nottingham, Kingston upon Hull, Newcastle upon
                Tyne, Stoke-on-Trent, Southampton, Derby, Portsmouth, Brighton,
                Plymouth, Northampton, Reading
              </p>
            </div>
          </section>
        </div>
      </main>
      <NewFooter />
    </>
  );
};

export default Home;
