import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginbg } from "../../assets/images/base64/loginbg";
import logo from "../../assets/images/logo/logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button, Spinner } from "@material-tailwind/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { signUpApi } from "../../services/ApiServices";
import toast from "react-hot-toast";

const SignUp = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (token) {
      navigate(`/`);
    }
  }, [token]);

  const validationSchema = yup.object().shape({
    emailId: yup
      .string()
      .required("Please enter Your Email Id")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=-{};:"<>,./?]).*$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  });

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      const params = {
        emailId: values.emailId,
        password: values.password,
      };

      signUpApi(params)
        .then((res) => {
          const message = res.data.message;
          setLoader(false);

          toast.success(message || "Create your account Successfully!!!");
          navigate("/verification", {
            state: {
              email_id: values.emailId,
              user_token: "",
            },
          });
        })
        .catch((err) => {
          const message = err?.response?.data?.message;
          setLoader(false);
          console.log("err >>>", err);
          if (message) {
            toast.error(message || "Somthing went wrong!!");
          }
        });
    },
  });

  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 w-full 2xl:block xl:block lg:block hidden h-screen">
          <img
            src={loginbg}
            className="object-cover w-full h-full"
            alt=""
          ></img>
        </div>
        <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 flex justify-center items-center">
          <div className="h-screen justify-center items-center flex">
            <div className="2xl:py-10 xl:py-10 lg:py-8 md:py-8 py-6 mx-5 2xl:min-w-96 xl:min-w-80 lg:min-w-80 md:min-w-80 min-w-72 w-auto">
              <div className="flex justify-center 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-[92px] 2xl:py-10 py-5 2xl:hidden xl:hidden lg:hidden block">
                <img src={logo} className="md:w-44 w-40" alt="" />
              </div>
              <div className="pt-4 mb-2">
                <h1 className="text-3xl font-medium">Sign up</h1>
              </div>
              <div className="my-4">
                <p className="text-base font-medium">
                  Do you have an account?{" "}
                  <b
                    className="text-primary font-semibold"
                    onClick={(e) => navigate("/signin")}
                  >
                    Log in
                  </b>
                </p>
              </div>
              <div className="py-4">
                <form onSubmit={formik.handleSubmit}>
                  <div className="">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                      htmlFor="regular-form-1"
                    >
                      Email
                    </label>
                    <input
                      id="regular-form-1"
                      name="emailId"
                      type="email"
                      placeholder="Enter Email"
                      className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.emailId}
                    />
                    {formik.touched.emailId && formik.errors.emailId ? (
                      <div className="text-danger my-1 text-sm">
                        {formik.errors.emailId}
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                      htmlFor="regular-form-2"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        id="regular-form-2"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        className="focus:outline-none py-3 ps-4 pe-11 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#37489B] sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <div className="" onClick={handleShowPassword}>
                        {showPassword ? (
                          <FaEye className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                        ) : (
                          <FaEyeSlash className="absolute text-gray-900 h-5 w-5 top-4 end-4 cursor-pointer" />
                        )}
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="my-6 text-center">
                    <Button
                      className="flex items-center justify-center w-full text-sm py-3 rounded-lg bg-primary capitalize font-normal"
                      type="submit"
                    >
                      <span>Sign Up</span>{" "}
                      {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
